import * as webgl from "./webgl"
import HeadlessDetect from "./headless"
import BrowserDetect from './browser'

const headlessDetector = new HeadlessDetect();

const save = (canvas, browsing) => {
  if ([].includes(browsing)) {
    return canvas.toDataURL();
  }
  return browsing;
};

const makeCanvasContext = (context = "2d") => {
  const canvas = document.createElement("canvas");
  canvas.width = 240;
  canvas.height = 140;
  canvas.style.display = "inline";
  let canvasContext = false;
  try {
    canvasContext = canvas.getContext(context);
  }
  catch {
    canvasContext = false;
  }

  return [canvas, canvasContext];
};

const isSupported = (canvas, context) => {
  return !!(context && canvas.toDataURL);
};

const getBrowser = async() => {
  return BrowserDetect(window.navigator.userAgent);
};



const getNavigatorData = () => {
  return {
    appCodeName: window.navigator.appCodeName,
    appName: window.navigator.appName,
    appVersion: window.navigator.appVersion,
    cookieEnabled: window.navigator.cookieEnabled,
    cpuClass: window.navigator.cpuClass,
    geolocation: window.navigator.geolocation,
    language: window.navigator.language,
    onLine: window.navigator.onLine,
    oscpu: window.navigator.oscpu,
    platform: window.navigator.platform,
    product: window.navigator.product,
    productSub: window.navigator.productSub,
    vendor: window.navigator.vendor,
    userAgent: window.navigator.userAgent
  };
};

const getWebAudioData = async() => {
  const AudioContext = window.AudioContext || window.webkitAudioContext;

  const audioContext = new AudioContext();

  return {
    baseLatency: audioContext.baseLatency ?
      audioContext.baseLatency.toString() : "",
    outputLatency: audioContext.outputLatency ?
      audioContext.outputLatency.toString() : "",
    rate: audioContext.sampleRate
  };
};

const getWebGLData = () => {
  const [canvas, context] = makeCanvasContext("webgl");
  let build = {};
  if (context) {
    build = webgl.describe(1, canvas, context);
    context.viewport(
      0,
      0,
      context.drawingBufferWidth,
      context.drawingBufferHeight
    );

    // Enable scissoring operation and define the position and
    // size of the scissoring area.
    context.enable(context.SCISSOR_TEST);
    context.scissor(40, 20, 60, 130);

    // Clear the drawing buffer solid yellow.
    context.clearColor(1.0, 1.0, 0.0, 1.0);
    context.clear(context.COLOR_BUFFER_BIT);
  }
  return {
    build: build
  };
};

const getCanvasData = async(browsing) => {
  const [canvas, context] = makeCanvasContext();

  if (!isSupported(canvas, context)) {
    return { winding: false, data: browsing };
  }

  // detect browser support of canvas winding
  // http://blogs.adobe.com/webplatform/2013/01/30/winding-rules-in-canvas/
  // https://github.com/Modernizr/Modernizr/blob/master/feature-detects/canvas/winding.js
  context.rect(0, 0, 10, 10);
  context.rect(2, 2, 6, 6);
  const winding = !context.isPointInPath(5, 5, "evenodd");

  context.textBaseline = "alphabetic";
  context.fillStyle = "#f60";
  context.fillRect(125, 1, 62, 20);
  context.fillStyle = "#069";
  // https://github.com/Valve/fingerprintjs2/issues/66
  // this can affect FP generation when applying different CSS on different websites
  context.font = "11pt no-real-font-123";
  // the choice of emojis has a gigantic impact on rendering performance (especially in FF)
  // some newer emojis cause it to slow down 50-200 times
  // context.fillText("Cw爨m fjordbank \ud83d\ude03 gly", 2, 15)
  const printedText = "Cwm fjordbank 😃 gly";
  context.fillText(printedText, 2, 15);
  context.fillStyle = "rgba(102, 204, 0, 0.2)";
  context.font = "18pt Arial";
  context.fillText(printedText, 4, 45);

  // canvas blending
  // http://blogs.adobe.com/webplatform/2013/01/28/blending-features-in-canvas/
  // http://jsfiddle.net/NDYV8/16/
  context.globalCompositeOperation = "multiply";
  context.fillStyle = "rgb(255,0,255)";
  context.beginPath();
  context.arc(50, 50, 50, 0, Math.PI * 2, true);
  context.closePath();
  context.fill();
  context.fillStyle = "rgb(0,255,255)";
  context.beginPath();
  context.arc(100, 50, 50, 0, Math.PI * 2, true);
  context.closePath();
  context.fill();
  context.fillStyle = "rgb(255,255,0)";
  context.beginPath();
  context.arc(75, 100, 50, 0, Math.PI * 2, true);
  context.closePath();
  context.fill();
  context.fillStyle = "rgb(255,0,255)";
  // canvas winding
  // http://blogs.adobe.com/webplatform/2013/01/30/winding-rules-in-canvas/
  // http://jsfiddle.net/NDYV8/19/
  context.arc(75, 75, 75, 0, Math.PI * 2, true);
  context.arc(75, 75, 25, 0, Math.PI * 2, true);
  context.fill("evenodd");
  const response = {
    data: save(canvas, browsing),
    winding
  };

  return response;
};

const composeDeviceData = async() => {

  let browsing = "unavailable"
  try {
    browsing = await getBrowser();
  }
  catch {}
  let audioDevices = "unavailable"
  try {
    audioDevices = await getWebAudioData();
  }
  catch {}
  let webGL = "unavailable"
  try {
    webGL = getWebGLData();
  }
  catch {}
  let canvas = "unavailable"
  try {
    canvas = await getCanvasData(browsing);
  }
  catch {}
  let headlessScore = "unavailable"
  try {
    headlessScore = headlessDetector.getHeadlessScore(browsing).toString();
  }
  catch {}
  return {
    audioDevices,
    webGL,
    canvas,
    headlessScore,
    browsing
  };
};


export const inspectBrowser = async() => {
  let deviceData = "unavailable"
  try {
    deviceData = await composeDeviceData();
  }
  catch {}
  let navigatorData = "unavailable"
  try {
    navigatorData = getNavigatorData();
  }
  catch {}
  return {
    deviceData,
    navigatorData
  };
};
