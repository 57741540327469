export const BRAVE = "Brave"
export const OPERA = "Opera"
export const EDGE = "Edge"
export const FIREFOX = "Firefox"
export const IE = "IE"
export const CHROME = "Chrome"
export const SAFARI = "Safari"

const detectIE = (agent) => {
    try {
        var isIE = /*@cc_on!@*/ false || !!document.documentMode;
        if (!isIE) {
            // Fallback to UserAgent detection for IE
            if (navigator.userAgent.indexOf("MSIE") > 0) {
                return true
            }
            else {
                return false
            }
        }
        return true
    }
    catch {
        return false
    }
}

const detectEdge = (agent) => window.navigator.userAgent.indexOf("Edge") > -1 || window.navigator.userAgent.indexOf("Edg") > -1

const detectSafari = (agent) => /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

const detectOpera = (agent) => typeof window.opr !== "undefined"

const detectChrome = (agent) => {
    var isIOSChrome = agent.match("CriOS");

    if (isIOSChrome) {
        return true
    }
    else if (
        window.chrome !== null
    ) {
        return true
    }
    else {
        return false
    }
}

const detectFirefox = (agent) => agent.toLowerCase().indexOf('firefox') > -1

const detector = (userAgent) => {
    let hasBrave = false;


    try {
        hasBrave = navigator.brave;
    }
    catch {}
    if (hasBrave) return BRAVE
    if (detectIE(userAgent)) return IE
    if (detectEdge(userAgent)) return EDGE
    if (detectFirefox(userAgent)) return FIREFOX
    if (detectSafari(userAgent)) return SAFARI
    if (detectOpera(userAgent)) return OPERA
    if (detectChrome(userAgent)) return CHROME
}

export default detector
