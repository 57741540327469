import * as browsers from './browser'
/*eslint-disable no-eval */

export default class HeadlessDetect {
    constructor() {
        this.allTestFunctions = [
            this.testAppVersion,
            this.testConnectionRtt,
            this.testEvalLength,
            this.testIsWebdriver,
            this.testUserAgent,
            this.testUserAgentSpoof,

        ];
        this.allTestFunctionsNames = [
            "testAppVersion",
            "testConnectionRtt",
            "testEvalLength",
            "testIsWebdriver",
            "testUserAgent",
            "testUserAgentSpoof",
        ];
    }

    //* All Tests *//

    testAppVersion() {
        let appVersion = window.navigator.appVersion;

        return /headless/i.test(appVersion) ? 1 : 0;
    }

    testConnectionRtt() {
        let connection = window.navigator.connection;
        let connectionRtt = connection ? connection.rtt : undefined;

        return connectionRtt === undefined ? 0 : connectionRtt === 0 ? 1 : 0
    }

    testEvalLength(browser) {
        const e = eval
        return ((e.toString().length === 33 && typeof window.chrome === 'undefined' && browser === browsers.CHROME) ||
            (e.toString().length === 37 && typeof window.netscape === 'undefined' && browser === browsers.FIREFOX)) ? 1 : 0
    }

    testIsWebdriver() {
        return window.navigator.webdriver === false ? 0 : 1
    }

    testUserAgent() {
        return /Headless/.test(window.navigator.userAgent) ? 1 : 0
    }

    testUserAgentSpoof() {
        if (window.navigator.platform.toLowerCase().startsWith('mac')) {
            return /macintosh/i.test(window.navigator.userAgent) ? 0 : 1
        }
        else if (window.navigator.platform.toLowerCase().startsWith('win')) {
            return /windows/i.test(window.navigator.userAgent) ? 0 : 1
        }
        else if (window.navigator.platform.toLowerCase().startsWith('linux')) {
            return /linux/i.test(window.navigator.userAgent) ? 0 : 1
        }
        else {
            return 0;
        }
    }


    //* Main Functions *//

    getHeadlessScore(browser) {
        let score = 0;
        let testsRun = 0;

        for (let i = 0; i < this.allTestFunctions.length; i++) {
            try {
                const scored = this.allTestFunctions[i](browser)
                score += scored
                testsRun++;
            }
            catch {}
        }
        return score / testsRun;
    }
}
