export const describe = (webglVersion, canvas, context) => {
    let report = {
        webglVersion: webglVersion
    };

    if (
        (webglVersion === 2 && !window.WebGL2RenderingContext) ||
        (webglVersion === 1 && !window.WebGLRenderingContext)
    ) {
        return {};
    }

    if (!context) {
        return {};
    }

    const possibleNames =
        webglVersion === 2 ? ["webgl2", "experimental-webgl2"] : ["webgl", "experimental-webgl"];

    const contextName = possibleNames.reduce((named, name) => {
        const canvas = document.createElement("canvas");
        context = canvas.getContext(name, { stencil: true });
        return !!context ? name : named;
    }, "");

    const describeRange = (value) => {
        return value ? "[" + value[0] + ", " + value[1] + "]" : "['unknown']";
    };

    const getMaxAnisotropy = () => {
        let e =
            context.getExtension("EXT_texture_filter_anisotropic") ||
            context.getExtension("WEBKIT_EXT_texture_filter_anisotropic") ||
            context.getExtension("MOZ_EXT_texture_filter_anisotropic");

        if (e) {
            let max = context.getParameter(e.MAX_TEXTURE_MAX_ANISOTROPY_EXT);
            // See Canary bug: https://code.google.com/p/chromium/issues/detail?id=117450
            if (max === 0) {
                max = 2;
            }
            return max;
        }
        return "n/a";
    };

    const formatPower = (exponent, verbose) => {
        if (verbose) {
            return "" + Math.pow(2, exponent);
        }
        else {
            return "2^" + exponent;
        }
    };

    const getPrecisionDescription = (precision, verbose) => {
        let verbosePart = verbose ? " bit mantissa" : "";
        return (
            "[-" +
            formatPower(precision.rangeMin, verbose) +
            ", " +
            formatPower(precision.rangeMax, verbose) +
            "] (" +
            precision.precision +
            verbosePart +
            ")"
        );
    };

    const getBestFloatPrecision = (shaderType) => {
        let high = context.getShaderPrecisionFormat(shaderType, context.HIGH_FLOAT);
        let medium = context.getShaderPrecisionFormat(
            shaderType,
            context.MEDIUM_FLOAT
        );
        let low = context.getShaderPrecisionFormat(shaderType, context.LOW_FLOAT);

        let best = high;
        if (typeof high === "object" && high.precision === 0) {
            best = medium;
        }
        if (typeof best === "object") {
            return [
                getPrecisionDescription(high, true),
                getPrecisionDescription(medium, true),
                getPrecisionDescription(low, true),
                getPrecisionDescription(best, false)
            ];
        }
    };

    const getFloatIntPrecision = (context) => {
        let high = context.getShaderPrecisionFormat(
            context.FRAGMENT_SHADER,
            context.HIGH_FLOAT
        );
        if (typeof high === "undefined") return "unknown";
        let s = high.precision !== 0 ? "highp/" : "mediump/";

        high = context.getShaderPrecisionFormat(
            context.FRAGMENT_SHADER,
            context.HIGH_INT
        );
        s += high.rangeMax !== 0 ? "highp" : "lowp";

        return s;
    };

    const isPowerOfTwo = (n) => {
        return n !== 0 && (n & (n - 1)) === 0;
    };

    const getAngle = (context) => {
        let lineWidthRange = describeRange(
            context.getParameter(context.ALIASED_LINE_WIDTH_RANGE)
        );

        // Heuristic: ANGLE is only on Windows, not in IE, and not in Edge, and does not implement line width greater than one.
        let angle =
            (navigator.platform === "Win32" || navigator.platform === "Win64") &&
            context.getParameter(context.RENDERER) !== "Internet Explorer" &&
            context.getParameter(context.RENDERER) !== "Microsoft Edge" &&
            lineWidthRange === describeRange([1, 1]);

        if (angle) {
            // Heuristic: D3D11 backend does not appear to reserve uniforms like the D3D9 backend, e.g.,
            // D3D11 may have 1024 uniforms per stage, but D3D9 has 254 and 221.
            //
            // We could also test for WEBGL_draw_buffers, but many systems do not have it yet
            // due to driver bugs, etc.
            if (
                isPowerOfTwo(
                    context.getParameter(context.MAX_VERTEX_UNIFORM_VECTORS)
                ) &&
                isPowerOfTwo(context.getParameter(context.MAX_FRAGMENT_UNIFORM_VECTORS))
            ) {
                return "Yes, D3D11";
            }
            else {
                return "Yes, D3D9";
            }
        }

        return "No";
    };

    const getMajorPerformanceCaveat = (contextName) => {
        // Does context creation fail to do a major performance caveat?
        let canvas = document.createElement("canvas");
        let context = canvas.getContext(contextName, {
            failIfMajorPerformanceCaveat: true
        });

        if (!context) {
            // Our original context creation passed.  This did not.
            return "Yes";
        }

        if (
            context.getContextAttributes() ?
            typeof context.getContextAttributes().failIfMajorPerformanceCaveat ===
            "undefined" :
            true
        ) {
            // If getContextAttributes() doesn't include the failIfMajorPerformanceCaveat
            // property, assume the browser doesn't implement it yet.
            return "Not implemented";
        }

        return "No";
    };


    function getMaxColorBuffers(context) {
        let maxColorBuffers = 1;
        let ext = context.getExtension("WEBGL_draw_buffers");
        if (ext != null)
            maxColorBuffers = context.getParameter(ext.MAX_DRAW_BUFFERS_WEBGL);

        return maxColorBuffers;
    }

    function getUnmaskedInfo(context) {
        let unMaskedInfo = {
            renderer: "",
            vendor: ""
        };

        let dbgRenderInfo = context.getExtension("WEBGL_debug_renderer_info");
        if (dbgRenderInfo != null) {
            unMaskedInfo.renderer = context.getParameter(
                dbgRenderInfo.UNMASKED_RENDERER_WEBGL
            );
            unMaskedInfo.vendor = context.getParameter(
                dbgRenderInfo.UNMASKED_VENDOR_WEBGL
            );
        }

        return unMaskedInfo;
    }


    function getWebGL2Status(context, contextName) {
        let webgl2Names = [
            "copyBufferSubData",
            "getBufferSubData",
            "blitFramebuffer",
            "framebufferTextureLayer",
            "getInternalformatParameter",
            "invalidateFramebuffer",
            "invalidateSubFramebuffer",
            "readBuffer",
            "renderbufferStorageMultisample",
            "texStorage2D",
            "texStorage3D",
            "texImage3D",
            "texSubImage3D",
            "copyTexSubImage3D",
            "compressedTexImage3D",
            "compressedTexSubImage3D",
            "getFragDataLocation",
            "uniform1ui",
            "uniform2ui",
            "uniform3ui",
            "uniform4ui",
            "uniform1uiv",
            "uniform2uiv",
            "uniform3uiv",
            "uniform4uiv",
            "uniformMatrix2x3fv",
            "uniformMatrix3x2fv",
            "uniformMatrix2x4fv",
            "uniformMatrix4x2fv",
            "uniformMatrix3x4fv",
            "uniformMatrix4x3fv",
            "vertexAttribI4i",
            "vertexAttribI4iv",
            "vertexAttribI4ui",
            "vertexAttribI4uiv",
            "vertexAttribIPointer",
            "vertexAttribDivisor",
            "drawArraysInstanced",
            "drawElementsInstanced",
            "drawRangeElements",
            "drawBuffers",
            "clearBufferiv",
            "clearBufferuiv",
            "clearBufferfv",
            "clearBufferfi",
            "createQuery",
            "deleteQuery",
            "isQuery",
            "beginQuery",
            "endQuery",
            "getQuery",
            "getQueryParameter",
            "createSampler",
            "deleteSampler",
            "isSampler",
            "bindSampler",
            "samplerParameteri",
            "samplerParameterf",
            "getSamplerParameter",
            "fenceSync",
            "isSync",
            "deleteSync",
            "clientWaitSync",
            "waitSync",
            "getSyncParameter",
            "createTransformFeedback",
            "deleteTransformFeedback",
            "isTransformFeedback",
            "bindTransformFeedback",
            "beginTransformFeedback",
            "endTransformFeedback",
            "transformFeedbackVaryings",
            "getTransformFeedbackVarying",
            "pauseTransformFeedback",
            "resumeTransformFeedback",
            "bindBufferBase",
            "bindBufferRange",
            "getIndexedParameter",
            "getUniformIndices",
            "getActiveUniforms",
            "getUniformBlockIndex",
            "getActiveUniformBlockParameter",
            "getActiveUniformBlockName",
            "uniformBlockBinding",
            "createVertexArray",
            "deleteVertexArray",
            "isVertexArray",
            "bindVertexArray"
        ];

        let webgl2 = contextName.indexOf("webgl2") !== -1;

        let functions = [];
        let totalImplemented = 0;
        let length = webgl2Names.length;

        if (webgl2) {
            for (let i = 0; i < length; ++i) {
                let name = webgl2Names[i];
                let className = "extension";
                if (webgl2 && context && context[name]) {
                    ++totalImplemented;
                }
                else {
                    className += " unsupported";
                }
                functions.push({ name: name, className: className });
            }
        }

        return {
            status: webgl2 ?
                totalImplemented + " of " + length + " new functions implemented." : "webgl2 and experimental-webgl2 contexts not available.",
            functions: functions
        };
    }

    let webgl2Status = getWebGL2Status(context, contextName);

    const webGL2report = context ? {
        contextName: contextName,
        glVersion: context.getParameter(context.VERSION),
        shadingLanguageVersion: context.getParameter(
            context.SHADING_LANGUAGE_VERSION
        ),
        vendor: context.getParameter(context.VENDOR),
        renderer: context.getParameter(context.RENDERER),
        unMaskedVendor: getUnmaskedInfo(context).vendor,
        unMaskedRenderer: getUnmaskedInfo(context).renderer,
        antialias: context.getContextAttributes() ?
            context.getContextAttributes().antialias ?
            "Available" :
            "Not available" : "Attributes Not available",
        angle: getAngle(context),
        majorPerformanceCaveat: getMajorPerformanceCaveat(contextName),
        maxColorBuffers: getMaxColorBuffers(context),
        redBits: context.getParameter(context.RED_BITS),
        greenBits: context.getParameter(context.GREEN_BITS),
        blueBits: context.getParameter(context.BLUE_BITS),
        alphaBits: context.getParameter(context.ALPHA_BITS),
        depthBits: context.getParameter(context.DEPTH_BITS),
        stencilBits: context.getParameter(context.STENCIL_BITS),
        maxRenderBufferSize: context.getParameter(
            context.MAX_RENDERBUFFER_SIZE
        ),
        maxCombinedTextureImageUnits: context.getParameter(
            context.MAX_COMBINED_TEXTURE_IMAGE_UNITS
        ),
        maxCubeMapTextureSize: context.getParameter(
            context.MAX_CUBE_MAP_TEXTURE_SIZE
        ),
        maxFragmentUniformVectors: context.getParameter(
            context.MAX_FRAGMENT_UNIFORM_VECTORS
        ),
        maxTextureImageUnits: context.getParameter(
            context.MAX_TEXTURE_IMAGE_UNITS
        ),
        maxTextureSize: context.getParameter(context.MAX_TEXTURE_SIZE),
        maxVaryingVectors: context.getParameter(context.MAX_VARYING_VECTORS),
        maxVertexAttributes: context.getParameter(context.MAX_VERTEX_ATTRIBS),
        maxVertexTextureImageUnits: context.getParameter(
            context.MAX_VERTEX_TEXTURE_IMAGE_UNITS
        ),
        maxVertexUniformVectors: context.getParameter(
            context.MAX_VERTEX_UNIFORM_VECTORS
        ),
        aliasedLineWidthRange: describeRange(
            context.getParameter(context.ALIASED_LINE_WIDTH_RANGE)
        ),
        aliasedPointSizeRange: describeRange(
            context.getParameter(context.ALIASED_POINT_SIZE_RANGE)
        ),
        maxViewportDimensions: describeRange(
            context.getParameter(context.MAX_VIEWPORT_DIMS)
        ),
        maxAnisotropy: getMaxAnisotropy(),
        vertexShaderBestPrecision: getBestFloatPrecision(context.VERTEX_SHADER),
        fragmentShaderBestPrecision: getBestFloatPrecision(
            context.FRAGMENT_SHADER
        ),
        fragmentShaderFloatIntPrecision: getFloatIntPrecision(context),

        extensions: context.getSupportedExtensions(),

        webgl2Status: webgl2Status.status,
        webgl2Functions: webgl2Status.functions
    } : {
        contextName: contextName,
        glVersion: "unavailable"
    };
    report = Object.assign(report, webGL2report);

    return report;
};
