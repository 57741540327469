import React, {useCallback, useEffect, useState} from "react"

import {useParams} from 'react-router-dom'

import Radio from '../Radio'
import CardBrand from "./cardBrand";

import * as func from './functions'
import * as encryption from './encryption'

import * as detective from '../detective'

import * as network from './network'

//import '../polyfill'

const getTiming = () => Math.round(Date.now())
const getEventTiming = (e) => Math.round(e.timeStamp ? e.timeStamp : Date.now())

//Action Constants
const HOST_TOKEN = "host:hostToken"
const TRANSFER_PART1 = "host:transfer_part1"
const TRANSFER_PART2 = "host:transfer_part2"
const TOKENIZE = "host:tokenize"
const CANCEL_TRANSFER = "host:cancel_transfer"
const BARCODE = "host:barcode"

const AUTOFILL = "field:autofill"
const KEYS = "field:keys"
const BLUR = "field:blur"
const CLICKS = "field:clicks"
const FOCUS = "field:focus"
const ERROR = "field:error"
const INSPECTION = "field:inspected"
const ATTESTATION = "field:attestation"

// Constants for incoming message types
const HOST_TOKEN_TYPE = "host_token"
const TRANSFER_CONFIRMATION_TYPE = "transfer_confirmation"
const CANCEL_TYPE = "cancel"
const BARCODE_COMPLETE_TYPE = "barcode_complete"
const TRANSFER_COMPLETE_TYPE = "transfer_complete"
const TOKENIZE_COMPLETE_TYPE = "tokenize_complete"
const ERROR_TYPE = "error"

const ENCRYPTED_MESSAGES = [TRANSFER_CONFIRMATION_TYPE, BARCODE_COMPLETE_TYPE, TRANSFER_COMPLETE_TYPE, TOKENIZE_COMPLETE_TYPE]


export default function HostedField({field}) {
    const {token} = useParams()

    const [changeCount, setChangeCount] = useState({count: 0})
    // const [priorChangeCount, setPriorChangeCount] = useState({ count: 0 })
    const [keyPrints, setKeyprints] = useState([])
    const [clickPrints, setClickPrints] = useState([])

  const [fieldsRecieved, setFieldsRecieved] = useState({})
  const [style, setStyle] = useState({})

    const [keyPair, setKeyPair] = useState(false)
    const [socketKey, setSocketKey] = useState(false)
    const [boxed, setBoxed] = useState(false)

  const [idempotency, setIdempotency] = useState(false)
  const [hostToken, setHostToken] = useState()
  const [tokenTimeout, setTokenTimeout] = useState(false)
  const [sessionKey, setSessionKey] = useState()

    const [accountName, setAccountName] = useState("")
    const [cashZip, setCashZip] = useState("")
    const [ccCvv, setCcCvv] = useState("")
    const [ccExp, setCcExp] = useState("")
    const [ccName, setCcName] = useState("")
    const [city, setCity] = useState("")
    const [contact, setContact] = useState("")
    const [line1, setLine1] = useState("")
    const [line2, setLine2] = useState("")
    const [routingNumber, setRoutingNumber] = useState("")
    const [state, setState] = useState("")
    const [value, setValue] = useState("")
    const [zip, setZip] = useState("")
    const [accountType, setAccountType] = useState("")
    const [cardBrand, setCardBrand] = useState("")

    const [autofill, setAutofill] = useState(false)
    const [paymentDetails, setPaymentDetails] = useState(false)
    const [tokenizeDetails, setTokenizeDetails] = useState(false)
    const [fieldsReady, setFieldsReady] = useState(false)
    const [focusSent, setFocusSent] = useState(false)
    const [focused, setFocused] = useState(false)
    const [inFocus, setInFocus] = useState(false)
    const [blurSent, setBlurSent] = useState(false)
    const [blurred, setBlurred] = useState(false)
    const [inspected, setInspected] = useState(false)
    const [isConnected, setIsConnected] = useState(false)
    const [liveStyle, setLiveStyle] = useState(false)
    const [origin, setOrigin] = useState(false)
    const [ptSocket, setPtSocket] = useState(false)
    const [ptToken, setPtToken] = useState(false)
    const [hidePlaceholder, setHidePlaceholder] = useState(false)

    const [transactingTypes] = useState(['card-number', 'account-number', 'cash-name'])
    const [valid, setValid] = useState([])

    //reset state passed by siblings so that the useEffect hook used to call the transferPart1 function is not called unintentionally
    const resetState = useCallback(() => {
        setAutofill(false)
        setAccountName("")
        setRoutingNumber("")
        setAccountType("")
        setCcCvv("")
        setCcExp("")
        setCcName("")
        setCity("")
        setLine1("")
        setLine2("")
        setState("")
        setZip("")
    }, [])

    //Creates the hidden fields for autocomplete of card and billing address
    const setHiddenFields = () => {
        let result;

        const name = <input key="cc-name" className="hidden" required="" autoComplete="cc-name" tabIndex="-1" value={ccName} id="hidden-name" onChange={e => setCcName(e.target.value)}/>;
        const cvc = <input key="cc-cvv" className="hidden" required="" autoComplete="cc-csc" tabIndex="-1" value={ccCvv} id="hidden-cvv" onChange={e => setCcCvv(e.target.value)}/>;
        const exp = <input key="cc-exp" className="hidden" required="" autoComplete="cc-exp" tabIndex="-1" value={ccExp} id="hidden-exp" onChange={e => setCcExp(e.target.value)}/>;
        const billLine1 = <input key="cc-line1" className="hidden" required="" autoComplete="address-line1" tabIndex="-1" value={line1} id="hidden-line1" onChange={e => setLine1(e.target.value)}/>;
        const billLine2 = <input key="cc-line2" className="hidden" required="" autoComplete="address-line2" tabIndex="-1" value={line2} id="hidden-line2" onChange={e => setLine2(e.target.value)}/>;
        const billCity = <input key="cc-city" className="hidden" required="" autoComplete="address-level2" tabIndex="-1" value={city} id="hidden-city" onChange={e => setCity(e.target.value)}/>;
        const billState = <input key="cc-state" className="hidden" required="" autoComplete="address-level1" tabIndex="-1" value={state} id="hidden-state" onChange={e => setState(e.target.value)}/>;
        const billZip = <input key="cc-zip" className="hidden" required="" autoComplete="postal-code" tabIndex="-1" value={zip} id="hidden-zip" onChange={e => setZip(e.target.value)}/>;

        switch (field.type) {
            case 'card-number':
                result = [name, cvc, exp, billLine1, billLine2, billCity, billState, billZip]
                break
            case 'billing-line1':
                result = [billLine2, billCity, billState, billZip]
                break
            default:
                //Do nothing because it is only needed for the two fields
                break
        }
        return result
    }


    const MOUSE_UP = "up"
    const MOUSE_DOWN = "down"
    const MOUSE_CONTEXT = "context"

    const onMouse = useCallback((eventType) => (e) => {
        const clickPrint = {
            mouse: eventType, button: e.button, timing: getEventTiming(e)
        }
        setClickPrints(clickPrints.concat([clickPrint]))
    }, [clickPrints])

    const onKeyUp = useCallback((e) => {
        const keyPrint = {
            metaKey: e.metaKey, repeat: e.repeat, charType: findCharType(e.key), timing: getEventTiming(e)
        }
        setChangeCount({
            count: changeCount.count + 1, keyPrint
        })
        setKeyprints(keyPrints.concat([keyPrint]))
    }, [keyPrints, changeCount.count])

    const findCharType = (key) => {
        let numeric = /[0-9]/
        return numeric.test(key) ? 'numeric' : key
    }

    //This function is used to send messages to the parent frame
    const sendMessage = useCallback(message => {
        window.parent.postMessage(message, origin);
    }, [origin])

    //This function is used to tell transacting fields that they have received all info from the siblings
    const verifyFieldsRecieved = obj => {
        return Object.keys(obj).reduce((acc, val) => {
            return acc ? (obj[val] !== false) : acc
        }, true)
    }

    //Sets the fields received object back to false so that the siblings can send new info
    const resetFieldsRecieved = useCallback(obj => {
        setFieldsReady(false)
        Object.keys(obj).forEach(v => {
            obj[v] = false
        })
    }, [])

    //Message types that are expected incoming from parent
    const attestationTypeMessage = message => typeof message.type === 'string' && message.type === 'pt-static:attestation'
    const transactTypeMessage = message => typeof message.type === 'string' && message.type === 'pt-static:transact'
    const relayTypeMessage = message => typeof message.type === 'string' && message.type === 'pt-static:relay'
    const elementsTypeMessage = message => typeof message.type === 'string' && message.type === 'pt-static:elements'
    const connectionTypeMessage = message => typeof message.type === 'string' && message.type === 'pt-static:connected'
    const cancelTypeMessage = message => typeof message.type === 'string' && message.type === 'pt-static:cancel'
    const confirmTypeMessage = message => typeof message.type === 'string' && message.type === 'pt-static:confirm'
    const resetHostTypeMessage = message => typeof message.type === 'string' && message.type === 'pt-static:reset_host'
    const paymentDetailTypeMessage = message => typeof message.type === 'string' && message.type === 'pt-static:payment-detail'
    const tokenizeDetailTypeMessage = message => typeof message.type === 'string' && message.type === 'pt-static:tokenize-detail'

    //This function is used to handle messages from the sibling frames to update the fields ready
    const setReceivedHook = (setVal) => (message) => {
        setVal(message.value)
        fieldsRecieved[message.element] = true
        setFieldsReady(verifyFieldsRecieved(fieldsRecieved))
    }

    const propagateAutofill = message => {
        setValue(message.value[field.type])
    }

    const relayMethods = {
        'routing-number': setReceivedHook(setRoutingNumber),
        'account-name': setReceivedHook(setAccountName),
        'account-type': setReceivedHook(setAccountType),
        'card-cvv': setReceivedHook(setCcCvv),
        'card-exp': setReceivedHook(setCcExp),
        'card-name': setReceivedHook(setCcName),
        'billing-line1': setReceivedHook(setLine1),
        'billing-line2': setReceivedHook(setLine2),
        'billing-city': setReceivedHook(setCity),
        'billing-state': setReceivedHook(setState),
        'billing-zip': setReceivedHook(setZip),
        'cash-contact': setReceivedHook(setContact),
        'cash-zip': setReceivedHook(setCashZip)
    }

    //Handles relay messages from the sibling frams for either autofill or single values
    const relayHandler = message => message.element.endsWith('autofill') ? propagateAutofill(message) : relayMethods[message.element](message)

  //Checks to make sure we have everything needed to send to the socket and then sends it or adds it to a message backlog to be sent after reconnection
  const socketAction = useCallback(async (action, sessionKey, socket, encoded) => {
    encoded.sessionKey = sessionKey
    if (socket.readyState === socket.OPEN && action === HOST_TOKEN) {
      //We can send the websocket action as long as the socket is open
      let message = { action, encoded: window.btoa(JSON.stringify(encoded)) }
      socket.send(JSON.stringify(message));
    } else if(socket.readyState === socket.OPEN){
      //We can send encrypted messages the websocket action as long as the socket is open and we have the boxed and keypair values
      let message = { action, sessionKey: window.btoa(sessionKey), encoded: encryption.encrypt(boxed, encoded), publicKey: encryption.encodeKey(keyPair.publicKey) }
      socket.send(JSON.stringify(message));
    } else if(action.includes('host')) {
        // Only throw an error if the action we are trying to send is a host action
      sendMessage({
        type: `pt-static:error`,
        error: `SOCKET_ERROR: Unable to send message to socket. Socket is not open.`,
        field: field.type
      })
    }
  }, [keyPair, boxed, transactingTypes, field.type])

    const requestHostToken = useCallback((token, origin, socket) => socketAction(HOST_TOKEN, sessionKey, socket, {
        ptToken: token, origin, timing: getTiming()
    }), [HOST_TOKEN, socketAction, sessionKey])

    const requestTransferPart1 = useCallback((hostToken, payment_method_data, payment_data, confirmation_needed, payor_info, pay_theory_data, metadata, socket, sessionKey) => {
        let encoded = {
            hostToken,
            payment_method_data,
            payment_data,
            confirmation_needed,
            payor_info,
            pay_theory_data,
            metadata,
            timing: getTiming()
        }
        socketAction(TRANSFER_PART1, sessionKey, socket, encoded)
    }, [resetState, socketAction])

  const requestTransferPart2 = useCallback((payment_prep, tags, socket, sessionKey) => 
    socketAction(TRANSFER_PART2, sessionKey, socket, { payment_prep, tags, timing: getTiming() }), [TRANSFER_PART2, socketAction])

  const requestCancel = useCallback((payment_intent_id, socket, sessionKey) => 
    socketAction(CANCEL_TRANSFER, sessionKey, socket, { payment_intent_id, timing: getTiming() }), [CANCEL_TRANSFER, socketAction])

    const requestBarcode = useCallback((hostToken, payment, metadata, payor_info, pay_theory_data, socket, sessionKey) => {
        let encoded = {
            payment,
            timing: getTiming(),
            hostToken,
            metadata,
            payor_info,
            pay_theory_data
        }
        socketAction(BARCODE, sessionKey, socket, encoded)
    }, [BARCODE, socketAction])

    const requestTokenize = useCallback((hostToken, payment_method_data, payment_data, payor_info, pay_theory_data, metadata, socket, sessionKey) => {
        let encoded = {
            hostToken,
            payment_method_data,
            payment_data,
            payor_info,
            pay_theory_data,
            metadata,
            timing: getTiming()
        }
        socketAction(TOKENIZE, sessionKey, socket, encoded)
    }, [TOKENIZE, socketAction])

    const sendAutofill = useCallback((sessionKey, socket, field, autofillPrint) => socketAction(AUTOFILL, sessionKey, socket, { field, autofillPrint, sessionKey, timing: getTiming()
    }), [AUTOFILL, socketAction])

    const sendBlur = useCallback((sessionKey, socket, field, blurPrint) => socketAction(BLUR, sessionKey, socket, { field, blurPrint, sessionKey, timing: getTiming()
    }), [BLUR, socketAction])

    const sendClickPrints = useCallback((sessionKey, socket, field, clickPrints) => socketAction(CLICKS, sessionKey, socket, { field, clickPrints, sessionKey, timing: getTiming()
    }), [CLICKS, socketAction])

    const sendFocus = useCallback((sessionKey, socket, field, focusPrint) => socketAction(FOCUS, sessionKey, socket, { field, focusPrint, sessionKey, timing: getTiming()
    }), [FOCUS, socketAction])

    const sendKeyPrints = useCallback((sessionKey, socket, field, keyPrints) => socketAction(KEYS, sessionKey, socket, { field, keyPrints, sessionKey, timing: getTiming()
    }), [KEYS, socketAction])

    const sendInspection = useCallback((sessionKey, socket, field, inspection) => socketAction(INSPECTION, sessionKey, socket, { field, inspection, sessionKey, timing: getTiming()
    }), [INSPECTION, socketAction])

    const sendAttestation = useCallback((sessionKey, socket, field, attestation) => socketAction(ATTESTATION, sessionKey, socket, { field, attestation, sessionKey, timing: getTiming()
    }), [ATTESTATION, socketAction])

    const sendError = useCallback((sessionKey, socket, field, error) => socketAction(ERROR, sessionKey, socket, { field, error, sessionKey, timing: getTiming()
    }), [ERROR, socketAction])

    const KEY_PRINT_TYPE = "key"
    const CLICK_PRINT_TYPE = "click"

    const releasePrints = useCallback((sessionKey, ptSocket, field, prints, printType) => {
        if (Array.isArray(prints)) {
            if (printType === KEY_PRINT_TYPE) {
                sendKeyPrints(sessionKey, ptSocket, field, prints)
                setKeyprints([])
            }
            if (printType === CLICK_PRINT_TYPE) {
                sendClickPrints(sessionKey, ptSocket, field, prints)
                setClickPrints([])
            }
        }
    }, [sendKeyPrints, sendClickPrints])

    const attestationHandler = useCallback((attestation) => {
        if (transactingTypes.includes(field.type)) {
            sendAttestation(sessionKey, ptSocket, field, attestation)
        }
    }, [field, sendAttestation, ptSocket, sessionKey, transactingTypes])

    const transactHandler = useCallback(() => {
        if (valid.length === 0 && !transactingTypes.includes(field.type)) {
            sendMessage({
                type: `pt-static:relay`, value, element: field.type
            })
        }
    }, [valid, value, field.type, sendMessage, transactingTypes])

    useEffect(() => {
        if (!keyPair) {
            setKeyPair(encryption.generateKeyPair())
        }
    }, [keyPair])

    useEffect(() => {
        if (!boxed && keyPair && socketKey) {
            const box = encryption.pairedBox(socketKey, keyPair.secretKey)
            setBoxed(box)
        }
    }, [keyPair, socketKey, boxed])


    useEffect(() => {
        if (ptToken && transactingTypes.includes(field.type) && isConnected === false) {
            setPtSocket(network.createSocket(ptToken))
        }
    }, [ptToken, transactingTypes, field.type, isConnected])

    useEffect(() => {
        if (ptSocket && sessionKey && transactingTypes.includes(field.type) && changeCount.count > 0 && inspected === false && boxed) {
            setInspected(true)
            try {
                (async () => {
                    try {
                        const inspected = await detective.inspectBrowser()
                        sendInspection(sessionKey, ptSocket, field.type, inspected)
                    } catch (e) {
                        sendError(sessionKey, ptSocket, field.type, e)
                    }
                })()
            } catch (error) {
                sendError(sessionKey, ptSocket, field.type, error)
            }

        }
    }, [ptSocket, sessionKey, field.type, sendInspection, sendError, transactingTypes, inspected, changeCount, boxed])

  useEffect(() => {
    if (token && !ptToken) {
      const json = decodeURI(token)
      const decodedJson = window.atob(json)
      const object = JSON.parse(decodedJson)
      setPtToken(object.token)
      setOrigin(object.origin)
      setStyle(object.styles ? object.styles : { default: {}, success: {}, error: {} })
      setHidePlaceholder(object.styles ? object.styles.hidePlaceholder : false)
      setLiveStyle(object.styles ? object.styles.default ? object.styles.default : {} : {})
    }
  }, [field.type, ptToken, sendMessage, token])

    useEffect(() => {
        if (!sessionKey || !boxed) return
        if (blurred && blurSent === false) {
            setBlurSent(blurred)
            sendBlur(sessionKey, ptSocket, field.type, blurred)

            releasePrints(sessionKey, ptSocket, field.type, keyPrints, KEY_PRINT_TYPE)
            releasePrints(sessionKey, ptSocket, field.type, clickPrints, CLICK_PRINT_TYPE)

            setFocused(false)
        }

    }, [blurred, ptSocket, sendBlur, blurSent, field.type, sessionKey, keyPrints, clickPrints, releasePrints, boxed])

    useEffect(() => {
        if (!sessionKey || !boxed) return
        if (focused && focusSent === false) {
            setFocusSent(focused)
            sendFocus(sessionKey, ptSocket, field.type, focused)
            setBlurred(false)
        }
    }, [focused, ptSocket, sendFocus, focusSent, field.type, sessionKey, boxed])

  const errorCallback = useCallback((event) => {
    console.log(`Error in field: ${field.type}, refresh required: ${event.error.message}`)
  }, [field.type])

    const messageCallback = useCallback((message) => {
        const data = JSON.parse(message.data)
        let body = data?.body
        if(ENCRYPTED_MESSAGES.includes(data?.type)) {
            const messagePublicKey = encryption.decodeKey(data.public_key)
            const messageBox = encryption.pairedBox(messagePublicKey, keyPair.secretKey)
            body = encryption.decrypt(messageBox, body)
        }

        switch (data?.type) {
            case ERROR_TYPE:
                sendMessage({
                    type: `pt-static:error`, error: `SOCKET_ERROR: ${body}`, field: field.type
                })
                break;
            case HOST_TOKEN_TYPE:
                setHostToken(body.hostToken)
                setSessionKey(body.sessionKey)
                setSocketKey(encryption.decodeKey(body.publicKey))
                if(tokenTimeout) clearTimeout(tokenTimeout)
                const hostTokenTimeout = setTimeout(() => {
                  // setTimeout to close the socket if still open after token expires 14 minutes later
                  if (ptSocket?.readyState === ptSocket?.OPEN && ptSocket) {
                    ptSocket.close()
                  }
                }, 14 * 60000)
                setTokenTimeout(hostTokenTimeout)
                sendMessage({
                    type: `pt-static:siblings`,
                    hostToken: body.hostToken,
                    sessionKey: body.sessionKey,
                    publicKey: body.publicKey,
                    field: field.type
                })
                break;
            case TRANSFER_CONFIRMATION_TYPE:
                setIdempotency(body)
                const confirmType = 'transfer'
                sendMessage({
                    type: `pt-static:confirm`, paymentType: confirmType, payment: body, field: field.type
                })
                break;
            case TRANSFER_COMPLETE_TYPE:
            case TOKENIZE_COMPLETE_TYPE:
                const completeType = TRANSFER_COMPLETE_TYPE === data.type ? 'transfer' : 'tokenize'
                const type = paymentDetails.confirmation ? 'confirmation-' : ''
                sendMessage({
                    type: `pt-static:${type}complete`, paymentType: completeType, body, field: field.type
                })
                break;
            case BARCODE_COMPLETE_TYPE:
                body.mapUrl = 'https://pay.vanilladirect.com/pages/locations'
                sendMessage({
                    type: `pt-static:cash-complete`, barcode: body, field: field.type
                })
                break;
            case CANCEL_TYPE:
                console.log('Transaction Canceled')
                break;
            default:
                sendMessage({
                    type: `pt-static:error`,
                    error: 'SOCKET_ERROR: There was an error with the socket.',
                    field: field.type
                })
                break;
        }

        if (data?.type !== HOST_TOKEN_TYPE) {
            resetState()
            resetFieldsRecieved(fieldsRecieved)
        }
    }, [resetState, resetFieldsRecieved, fieldsRecieved, sendMessage, field, paymentDetails])

    // const READY_CONNECTING = 0
    // const READY_OPEN = 1
    // const READY_CLOSING = 2
    // const READY_CLOSED = 3

  //Initializes the web socket and then sends a ready message
  useEffect(() => {
    if (ptSocket && ptToken && origin && isConnected === false) {
      const empty = () => {}
      const READY_ACTIONS = {
        0: () => {
          ptSocket.onopen = () => {
            if (transactingTypes.includes(field.type)) {
              requestHostToken(ptToken, origin, ptSocket)
            }
          }
          ptSocket.onmessage = messageCallback
          ptSocket.onerror = errorCallback
          ptSocket.onclose = () => {
            sendMessage({
              type: `pt-static:error`,
              error: "SESSION_EXPIRED: The session has expired. Please refresh the page.",
              field: field.type
            })
          }
          setIsConnected(true)
        },
        1: empty,
        2: empty,
        3: empty
      }
      READY_ACTIONS[ptSocket.readyState]()
    }
  }, [ptSocket, ptToken, requestHostToken, field, fieldsRecieved, transactingTypes, messageCallback, origin, isConnected, errorCallback])

    useEffect(() => {
        if (ptSocket && messageCallback) {
            ptSocket.onmessage = messageCallback
        }
    }, [ptSocket, messageCallback])

    //Sends a ready message to the SDK
    useEffect(() => {
        if (origin) {
            sendMessage({
                type: `pt-static:${field.type}:ready`, ready: true, element: field.type
            })
        }
    }, [field, origin, sendMessage])

    //Accepts the processed elements array to allow for us to check that all state has been received before sending pt-instrument call
    const elementsHandler = message => {
        let result = {}
        message.elements.forEach(e => {
            if (e.type === 'credit-card') {
                result['card-exp'] = false
                result['card-cvv'] = false
            } else {
                if (!transactingTypes.includes(e.type)) result[e.type] = false
            }
        })
        setFieldsRecieved(result)
    }

    const confirmHandler = useCallback(() => {
        if (paymentDetails) requestTransferPart2(idempotency, paymentDetails.metadata, ptSocket, sessionKey)
    }, [requestTransferPart2, idempotency, paymentDetails, ptSocket, sessionKey])

  //Once all fields on the dom are connected to the socket the SDK sends a message to the transactional fields to call for the host token
  const connectionHandler = message => {
    if (!transactingTypes.includes(field.type)) {
      setSessionKey(message.sessionKey)
      setSocketKey(encryption.decodeKey(message.publicKey))
      setPtSocket(network.createSocket(message.hostToken))
    }
  }

    //Fetch new host token on message
    const resetHostTokenHandler = message => {
        requestHostToken(message.token, message.origin, ptSocket)
    }

    //Send cancel message to update the payment intent
    const cancelHandler = useCallback(() => {
        let paymentIntent = idempotency['payment_intent_id']
        requestCancel(paymentIntent, ptSocket, sessionKey)
        setIdempotency(false)
    }, [requestCancel, idempotency, ptSocket, sessionKey])

    //Sets the details used for the transaction message to the socket
    const paymentDetailHandler = message => {
        setPaymentDetails(message.data)
    }

    // Sets the details used for tokenizing a payment method to the socket
    const tokenizeDetailHandler = message => {
        setTokenizeDetails(message.data)
    }


    //Checks input events to validate autofill for Firefox, Edge, IE11, and iOS
    const inputCheckAutoComplete = event => {
        if (('insertReplacementText' === event.inputType || !('data' in event)) && event.target.id === `${field.type}-hosted-field`) {
            setAutofill(true)
        }
    }

    //Checks animationstart events to validate autofill for -webkit based browsers (Chrome and Safari)
    const animationCheckAutoComplete = event => {
        if ('onautofillstart' === event.animationName && event.target.id === `${field.type}-hosted-field`) {
            setAutofill(true)
        }
    }

    const generateWindowListener = (validTarget, handleMessage) => event => {
        const message = func.isJson(event.data) ? JSON.parse(event.data) : event.data
        if (validTarget(message)) {
            handleMessage(message)
        }
    }

    //callbacks for event listeners so they can be removed/updated when state changes
    const attestationCallback = generateWindowListener(attestationTypeMessage, attestationHandler)
    const transactCallback = generateWindowListener(transactTypeMessage, transactHandler)
    const relayCallback = generateWindowListener(relayTypeMessage, relayHandler)
    const elementsCallback = generateWindowListener(elementsTypeMessage, elementsHandler)
    const connectionCallback = generateWindowListener(connectionTypeMessage, connectionHandler)
    const cancelCallback = generateWindowListener(cancelTypeMessage, cancelHandler)
    const paymentDetailsCallback = generateWindowListener(paymentDetailTypeMessage, paymentDetailHandler)
    const resetHostTokenCallback = generateWindowListener(resetHostTypeMessage, resetHostTokenHandler)
    const confirmCallback = generateWindowListener(confirmTypeMessage, confirmHandler)
    const tokenizeCallback = generateWindowListener(tokenizeDetailTypeMessage, tokenizeDetailHandler)


    //adds and removes event listners
    useEffect(() => {
        window.addEventListener('oncontextmenu', onMouse(MOUSE_CONTEXT));
        window.addEventListener('onmousedown', onMouse(MOUSE_DOWN));
        window.addEventListener('onmouseup', onMouse(MOUSE_UP));
        window.addEventListener('onkeyup', onKeyUp);
        window.addEventListener('message', attestationCallback);
        window.addEventListener('message', transactCallback);
        window.addEventListener('message', relayCallback);
        window.addEventListener('message', elementsCallback);
        window.addEventListener('message', connectionCallback);
        window.addEventListener('message', cancelCallback);
        window.addEventListener('message', paymentDetailsCallback);
        window.addEventListener('animationstart', animationCheckAutoComplete);
        window.addEventListener('input', inputCheckAutoComplete);
        window.addEventListener('message', resetHostTokenCallback);
        window.addEventListener('message', confirmCallback);
        window.addEventListener('message', tokenizeCallback);


        return () => {
            window.removeEventListener('oncontextmenu', onMouse(MOUSE_CONTEXT));
            window.removeEventListener('onmousedown', onMouse(MOUSE_DOWN));
            window.removeEventListener('onmouseup', onMouse(MOUSE_UP));
            window.removeEventListener('onkeyup', onKeyUp);
            window.removeEventListener('message', attestationCallback);
            window.removeEventListener('message', transactCallback);
            window.removeEventListener('message', relayCallback);
            window.removeEventListener('message', elementsCallback);
            window.removeEventListener('message', connectionCallback);
            window.removeEventListener('message', cancelCallback);
            window.removeEventListener('message', paymentDetailsCallback);
            window.removeEventListener('animationstart', animationCheckAutoComplete);
            window.removeEventListener('input', inputCheckAutoComplete);
            window.removeEventListener('message', resetHostTokenCallback);
            window.removeEventListener('message', confirmCallback);
            window.removeEventListener('message', tokenizeCallback);
        };
    }, [value, valid, attestationCallback, relayCallback, transactCallback, elementsCallback, connectionCallback, cancelCallback, paymentDetailsCallback, ptSocket, onKeyUp, onMouse, resetHostTokenCallback, confirmCallback, tokenizeCallback])

    //update state elements for the JS SDK
    useEffect(() => {
        if (origin) {
            setValid(func.validCheck(field.type, value))
            sendMessage({
                type: `pt-static:state`, state: {
                    isFocused: inFocus,
                    isDirty: value.length > 0,
                    errorMessages: func.validCheck(field.type, value, isConnected)
                }, element: field.type
            })
        }
    }, [value, inFocus, field.type, origin, sendMessage, isConnected])

    //updates the style based on the value of the field
    useEffect(() => {
        if (liveStyle) {
            if (style.success && value.length > 0 && valid.length === 0) {
                setLiveStyle(style.success)
            } else if (style.error && value.length > 0 && valid.length > 0) {
                setLiveStyle(style.error)
            } else {
                setLiveStyle(style.default)
            }
        }
    }, [value, valid, style.success, style.error, style.default, liveStyle])

    // Send transfer part 1 or tokenize message once all state is received from hosted fields
    useEffect(() => {
        if ((func.notEmpty(accountName) && func.validAccountType(accountType) && func.validRoutingNumber(routingNumber) && func.validAccountNumber(value) && (paymentDetails || tokenizeDetails) && fieldsReady) || (func.validCvv(ccCvv) && func.validExp(ccExp) && func.validCreditCard(value) && func.validPostalCode(zip) && (paymentDetails || tokenizeDetails) && fieldsReady)) {
            const getPayorInfo = payorInfo => {
                if (payorInfo.sameAsBilling === true) {
                    const nameArray = ccName.split(' ')
                    const firstName = nameArray.shift()
                    const lastName = nameArray.join(' ')

                    const fieldInfo = {
                        first_name: firstName,
                        last_name: lastName,
                        "personal_address": {
                            "city": city,
                            "country": "USA",
                            "region": state,
                            "line1": line1,
                            "line2": line2,
                            "postal_code": zip
                        }
                    }
                    return {...payorInfo, ...fieldInfo}
                }
                return payorInfo
            }
            let payment_method_data;
            let [month, year] = ccExp.split('/')

            if (field.type === 'card-number') {
                payment_method_data = {
                    name: ccName,
                    number: value.replace(/\D/g, ''),
                    security_code: ccCvv,
                    type: "card",
                    expiration_year: year.length === 2 ? `20${year}` : year,
                    expiration_month: month,
                    address: {
                        city: city, region: state, postal_code: zip, line1, line2, country: "USA"
                    }
                }
            } else if (field.type === 'account-number') {
                payment_method_data = {
                    "account_number": value,
                    "account_type": accountType,
                    "bank_code": routingNumber,
                    "name": accountName,
                    "type": "ach"
                }
            }

            //Send tokenize message if that is the incoming type
            if (tokenizeDetails) {
                const payment_data = {
                    fee_mode: tokenizeDetails.fee_mode, currency: "USD", amount: tokenizeDetails.amount
                }

                let {metadata, payorInfo, payTheoryData} = tokenizeDetails
                payorInfo = getPayorInfo(payorInfo)
                requestTokenize(hostToken, payment_method_data, payment_data, payorInfo, payTheoryData, metadata, ptSocket, sessionKey)
            }
            if (paymentDetails) {
                const payment_data = {
                    fee_mode: paymentDetails.fee_mode, currency: "USD", amount: paymentDetails.amount
                }
                let {metadata, confirmation, payorInfo, payTheoryData} = paymentDetails
                payorInfo = getPayorInfo(payorInfo)
                requestTransferPart1(hostToken, payment_method_data, payment_data, confirmation, payorInfo, payTheoryData, metadata, ptSocket, sessionKey)
            }
        }
    }, [accountName, accountType, routingNumber, value, paymentDetails, ccCvv, ccExp, ccName, city, state, zip, line1, line2, fieldsReady, hostToken, ptSocket, field.type, sessionKey, requestTransferPart1, requestTokenize, tokenizeDetails])

    useEffect(() => {
        if (func.validCashContact(contact) && value && paymentDetails && fieldsReady) {
            let payment = {buyer: value, amount: paymentDetails.amount, buyer_contact: contact, zip: cashZip}
            let {metadata, payorInfo, payTheoryData} = paymentDetails
            requestBarcode(hostToken, payment, metadata, payorInfo, payTheoryData, ptSocket, sessionKey)
        }
    }, [contact, paymentDetails, value, cashZip, fieldsReady, sendMessage, hostToken, ptSocket, sessionKey, requestBarcode])

    //relays when autofill is used on the card number or line one component and sends an autofill message to the socket
    useEffect(() => {
        if (ccExp && ccName && autofill && ptSocket && sessionKey) {
            sendMessage({
                type: `pt-static:relay`,
                value: {'card-name': ccName, 'card-cvv': ccCvv, 'card-exp': ccExp},
                element: 'card-autofill'
            })
            resetState()
            if (!sessionKey || !boxed) return
            sendAutofill(sessionKey, ptSocket, field.type, {timing: getTiming()})
        } else if (city && state && zip && autofill && sessionKey) {
            sendMessage({
                type: `pt-static:relay`,
                value: {'billing-line2': line2, 'billing-city': city, 'billing-state': state, 'billing-zip': zip},
                element: 'address-autofill'
            })
            resetState()
            if (!sessionKey || !boxed) return
            sendAutofill(sessionKey, ptSocket, field.type, {timing: getTiming()})
        }
    }, [ccCvv, ccExp, ccName, line2, city, state, zip, autofill, sendAutofill, ptSocket, field.type, sessionKey, sendMessage, resetState, boxed])

    return (<div className={`${field.type}-field pt-hosted-field`}>
        {liveStyle ? field.type !== 'account-type' ? <input
            style={liveStyle}
            id={`${field.type}-hosted-field`}
            placeholder={hidePlaceholder ? '' : field.placeholder}
            value={value}
            autoComplete={field.autoComplete}
            type={field.numeric ? 'tel' : ''}
            pattern={field.numeric ? '[0-9]*' : ''}
            aria-label={field.aria}
            onBlur={(e) => {
                setBlurred({timing: getEventTiming(e)})
                setInFocus(false)
            }}
            onContextMenu={onMouse(MOUSE_CONTEXT)}
            onMouseDown={onMouse(MOUSE_DOWN)}
            onMouseUp={onMouse(MOUSE_UP)}
            onChange={(e) => {
                let result = e.target.value
                if (field.formatter) {
                    result = field.formatter(e.target.value)
                }
                if (field.type === "card-number") {
                    setCardBrand(func.cardBrand(result))
                }
                setValue(result);
            }}
            onFocus={(e) => {
                setFocused({timing: getEventTiming(e)})
                setInFocus(true)
            }}
            onKeyUp={onKeyUp}
        /> : <Radio styles={style.radio} value={value} setValue={setValue}/> : null}
        {field.type === 'card-number' || field.type === 'billing-line1' ? <div className="hidden-fields">
            {setHiddenFields().map(h => (h))}
        </div> : null}
        {field.type === 'card-number' ? <CardBrand cardBrand={cardBrand}/> : null}
        <style jsx="true">{`
        .pt-hosted-field .hidden-fields input {
          max-height: 2px;
          max-width: 2px;
          border-color: transparent;
          color: transparent;
          border: 0px;
          padding: 0px;
          position: relative;
        }
        .pt-hosted-fields .hidden-fields .hidden:-webkit-autofill,
        .pt-hosted-fields .hidden-fields .hidden:-webkit-autofill:hover,
        .pt-hosted-fields .hidden-fields .hidden:-webkit-autofill:focus {
          -webkit-text-fill-color: #fff !important;
          background: transparent;
        }
        .pt-hosted-field .hidden-fields {
          max-height: 2px;
          max-width: 2px;
          border-color: transparent;
          color: transparent;
          border: 0px;
          padding: 0px;
        }
      `}</style>
    </div>);
}
